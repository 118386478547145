import { Context, createContext, Dispatch, SetStateAction } from "react";
import "@microsoft/teams-js";
import IUserInfo from "../models/IUserInfo";

export interface IAppContext {
  userInfo: IUserInfo | undefined;
  setUserInfo: Dispatch<SetStateAction<IUserInfo | undefined>>;
  ssoToken: string | undefined;
  setSSOToken: Dispatch<SetStateAction<string | undefined>>;
  teamsContext: microsoftTeams.Context | undefined;
  setTeamsContext: Dispatch<SetStateAction<microsoftTeams.Context | undefined>>;
}

export const AppContext: Context<IAppContext> = createContext({
  userInfo: undefined,
  setUserInfo: () => undefined,
  ssoToken: undefined,
  setSSOToken: () => undefined,
  teamsContext: undefined,
  setTeamsContext: () => undefined,
} as IAppContext);
