import React from "react";
import {
  Toolbar,
  Tooltip,
  ToolbarItemShorthandKinds,
  ToolbarItemProps,
  ShorthandCollection,
  SiteVariablesPrepared,
} from "@fluentui/react-northstar";
import { EmailIcon, ChatIcon, CallIcon } from "@fluentui/react-icons-northstar";
import { useTranslation } from "react-i18next";
import * as microsoftTeams from "@microsoft/teams-js";
import IContactPresence from "../models/IContactPresence";

// styles
const variables = {
  toolbarItem: (siteVariables: SiteVariablesPrepared, isParentHovered: boolean) => {
    if (!isParentHovered) {
      return {};
    }

    return {
      foreground: siteVariables.colorScheme.brand.foreground4,
      foregroundHover: siteVariables.colorScheme.brand.foreground4,
    };
  },
};

// props
interface ContactToolbarProps {
  contact: IContactPresence;
  isParentHovered: boolean;
}

// render
const ContactToolbar = (props: ContactToolbarProps) => {
  const { contact, isParentHovered } = props;
  const { t } = useTranslation();
  const email =
    contact.info.scoredEmails && contact.info.scoredEmails.length > 0 ? contact.info.scoredEmails[0].email : "";
  const available = contact.presence?.availability || "PresenceUnknown";

  /* eslint-disable */
  const renderTooltip = (ToolbarItem: any, itemProps: any) => {
    const { tooltip, key, ...rest } = itemProps;

    if (rest.disabled) {
      return <ToolbarItem key={key} {...rest} />;
    }

    return <Tooltip key={key} trigger={<ToolbarItem {...rest} />} content={tooltip} />;
  };
  /* eslint-disable */

  const openMail = () => {
    window.location.href = `mailto:${email}`;
  };

  const openChat = () => {
    microsoftTeams.executeDeepLink(`https://teams.microsoft.com/l/chat/0/0?users=${email}`);
  };

  const openCall = () => {
    microsoftTeams.executeDeepLink(`https://teams.microsoft.com/l/call/0/0?users=${email}`);
  };

  // items composed the toolbar
  let items: ShorthandCollection<ToolbarItemProps, ToolbarItemShorthandKinds> = [];

  // can contact by chat or by call internal users only
  if (contact.info.personType.subClass === "OrganizationUser") {
    items.push(
      ...([
        {
          icon: <ChatIcon {...{ outline: true }} />,
          key: "chat",
          tooltip: t("MyContacts.Actions.Chat"),
          children: renderTooltip,
          disabled: !email,
          onClick: openChat,
          variables: (siteVariables: SiteVariablesPrepared) => variables.toolbarItem(siteVariables, isParentHovered),
        },
        {
          icon: <CallIcon {...{ outline: true }} />,
          key: "call",
          tooltip: t("MyContacts.Actions.Call"),
          children: renderTooltip,
          onClick: openCall,
          disabled: !email || available.indexOf("Available") === -1,
          variables: (siteVariables: SiteVariablesPrepared) => variables.toolbarItem(siteVariables, isParentHovered),
        },
      ] as ShorthandCollection<ToolbarItemProps, ToolbarItemShorthandKinds>),
    );
  }

  // can contact all users by mail
  items.push({
    icon: <EmailIcon {...{ outline: true }} />,
    key: "mail",
    tooltip: t("MyContacts.Actions.Mail"),
    children: renderTooltip,
    onClick: openMail,
    disabled: !email,
    variables: (siteVariables: SiteVariablesPrepared) => variables.toolbarItem(siteVariables, isParentHovered),
  });

  return <Toolbar items={items} />;
};

export default ContactToolbar;
