import React, { useContext, useState } from "react";
import { Flex, Card, Text, pxToRem, ICSSInJSStyle } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import ContactAvatar from "./ContactAvatar";
import ScoreIndicator from "./ScoreIndicator";
import ContactToolbar from "./ContactToolbar";
import GlobeIcon from "../icons/GlobeIcon";
import GlobeAmericasIcon from "../icons/GlobeAmericasIcon";
import ContactStatus from "./ContactStatus";
import { MyContactsContext } from "../contexts/MyContactsContext";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  card: { marginBottom: pxToRem(8), justifyContent: "space-between", height: "auto" },
};

// props
interface ContactCardProps {
  contact: IContactPresence;
  isSelected: boolean;
  thresholdLow: number;
  thresholdMedium: number;
}

// render
const ContactCard = (props: ContactCardProps) => {
  const { contact, thresholdLow, thresholdMedium, isSelected } = props;
  const { showTeamContacts } = useContext(MyContactsContext);
  const [hover, setHover] = useState<boolean>(false);
  const availability = contact.presence?.availability || "PresenceUnknown";
  const { t } = useTranslation();

  return (
    <Card
      selected={isSelected}
      styles={styles.card}
      className={`${availability === "Available" ? "available" : "unavailable"} ${
        contact.info.personType.subClass === "OrganizationUser" ? "internal" : "external"
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card.Body fitted>
        <Flex gap="gap.small" space="between">
          <Flex column>
            <Text content={contact.info.name} weight="bold" size="large" />
            {!showTeamContacts && (
              <Flex>
                <Text content={t("MyContacts.Card.Relationship")} size="small" />
                <ScoreIndicator
                  contact={contact}
                  thresholdLow={thresholdLow}
                  thresholdMedium={thresholdMedium}
                  size="medium"
                />
              </Flex>
            )}
            <Text content={<ContactStatus contact={contact} />} size="small" />
          </Flex>
          <ContactAvatar contact={contact} size="larger" showPresence />
        </Flex>
      </Card.Body>
      <Card.Footer fitted>
        <Flex gap="gap.small" space="between" vAlign="end">
          <Flex column gap="gap.smaller">
            {contact.info.officeLocation && (
              <Flex gap="gap.smaller">
                <GlobeAmericasIcon size="small" />
                <Text content={contact.info.officeLocation} size="small" />
              </Flex>
            )}
            <Flex gap="gap.smaller" vAlign="center">
              <GlobeIcon size="small" />
              <Text
                content={
                  contact.info.scoredEmails.length > 0 ? contact.info.scoredEmails[0].email.split("@").pop() : undefined
                }
                size="small"
              />
            </Flex>
          </Flex>
          <ContactToolbar contact={contact} isParentHovered={hover || isSelected} />
        </Flex>
      </Card.Footer>
    </Card>
  );
};

const contactPropsAreEqual = (prevProps: ContactCardProps, nextProps: ContactCardProps) => {
  return (
    prevProps.contact.info.id === nextProps.contact.info.id &&
    prevProps.contact.presence?.availability === nextProps.contact.presence?.availability &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.thresholdLow === nextProps.thresholdLow &&
    prevProps.thresholdMedium === nextProps.thresholdMedium
  );
};

export const ContactCardMemo = React.memo(ContactCard, contactPropsAreEqual);
