import { useEffect, useState } from "react";
import {
  ThemeInput,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  mergeThemes,
  ThemePrepared,
} from "@fluentui/react-northstar";
import gdhCommonTheme from "../theme/common";
import gdhLightTheme from "../theme/light";
import gdhDarkTheme from "../theme/dark";
import gdhHighContrastTheme from "../theme/contrast";

const useTheme = () => {
  const [theme, setTheme] = useState<ThemeInput>(teamsTheme);
  const [themeName, setThemeName] = useState<string | undefined>(undefined);

  useEffect(() => {
    let mergedTheme: ThemePrepared;

    switch (themeName) {
      case "dark":
        mergedTheme = mergeThemes(teamsDarkTheme, gdhCommonTheme, gdhDarkTheme);
        break;
      case "contrast":
        mergedTheme = mergeThemes(teamsHighContrastTheme, gdhCommonTheme, gdhHighContrastTheme);
        break;
      case "default":
      default:
        mergedTheme = mergeThemes(teamsTheme, gdhCommonTheme, gdhLightTheme);
        break;
    }

    setTheme(mergedTheme);
  }, [themeName]);

  return { theme, setThemeName };
};

export default useTheme;
