import React, { useContext } from "react";
import { Table, ICSSInJSStyle } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import ContactRow from "./ContactRow";
import { MyContactsContext } from "../contexts/MyContactsContext";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  table: { overflow: "auto" },
  presenceCell: { flexGrow: 0, flexBasis: "40px", minHeight: "40px" },
  statusCell: { flexGrow: 0, flexBasis: "150px", minHeight: "40px" },
  relationshipCell: { flexGrow: 0, flexBasis: "80px", minHeight: "40px" },
  relationshipHiddenCell: { flexGrow: 0, flexBasis: "0px", display: "none", minHeight: "40px" },
  actionsCell: { flexGrow: 0, flexBasis: "160px", minHeight: "40px" },
};

// props
interface ContactsListViewProps {
  contacts: IContactPresence[];
  selectedContact: IContactPresence | undefined;
  thresholdLow: number;
  thresholdMedium: number;
}

// render
const ContactsListView = (props: ContactsListViewProps) => {
  const { contacts, selectedContact, thresholdLow, thresholdMedium } = props;
  const { showTeamContacts } = useContext(MyContactsContext);
  const { t } = useTranslation();

  const header = {
    key: "header",
    items: [
      {
        key: "presence",
        content: "",
        styles: styles.presenceCell,
      },
      {
        key: "status",
        content: t("MyContacts.Headers.Status"),
        styles: styles.statusCell,
      },
      {
        key: "name",
        content: t("MyContacts.Headers.Name"),
      },
      {
        key: "location",
        content: t("MyContacts.Headers.Location"),
      },
      {
        key: "relationship",
        content: t("MyContacts.Headers.Relationship"),
        styles: !showTeamContacts ? styles.relationshipCell : styles.relationshipHiddenCell,
      },
      {
        key: "domain",
        content: t("MyContacts.Headers.Domain"),
      },
      {
        key: "actions",
        content: "",
        styles: styles.actionsCell,
      },
    ],
  };

  return (
    <Table
      header={header}
      rows={contacts.map((c) => (
        <ContactRow
          key={c.info.id}
          contact={c}
          thresholdLow={thresholdLow}
          thresholdMedium={thresholdMedium}
          isSelected={(selectedContact && selectedContact.info.id === c.info.id) || false}
        />
      ))}
      styles={styles.table}
    />
  );
};

export default ContactsListView;
