import React from "react";
import { Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import IContactPresence from "../models/IContactPresence";

// props
interface ContactStatusProps {
  contact: IContactPresence;
}

// render
const ContactStatus = (props: ContactStatusProps) => {
  const { contact } = props;
  const { t } = useTranslation();
  const isExternal = contact.info.personType.subClass !== "OrganizationUser";

  let presenceKey = "External";
  if (contact.presence) {
    const { availability, activity } = contact.presence;
    presenceKey = activity !== availability ? activity : availability;

    if (presenceKey === "PresenceUnknown" && isExternal) {
      presenceKey = "External";
    }
  }

  let status = t(`MyContacts.Presence.${presenceKey}`);
  if (status.indexOf("MyContacts.Presence") === 0) status = presenceKey;
  return <Text content={status} />;
};

export default ContactStatus;
