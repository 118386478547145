import React, { useEffect, useState } from "react";
import { Avatar, Flex, ICSSInJSStyle, Image, SizeValue, Tooltip } from "@fluentui/react-northstar";
import * as gdhApi from "../api/gdh-api";
import ContactStatus from "./ContactStatus";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: { position: "relative" },
  presence: { position: "absolute", width: "16px", height: "16px", top: "48px", right: "0px" },
};

// props
interface ContactAvatarProps {
  contact: IContactPresence;
  showPresence?: boolean;
  size: SizeValue | undefined;
}

// render
const ContactAvatar = (props: ContactAvatarProps) => {
  const { contact, showPresence, size } = props;
  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const availability = contact.presence?.availability || "PresenceUnknown";

  useEffect(() => {
    gdhApi.User.getPhoto(contact.info).then((response) => {
      if (response.status === 200) {
        const objectURL = URL.createObjectURL(response.data);
        setPhoto(objectURL);

        return () => {
          URL.revokeObjectURL(objectURL);
          setPhoto(undefined);
        };
      }

      if (response.status === 204) {
        setPhoto(undefined);
      }

      return () => {};
    });
  }, [contact]);

  return (
    <Flex styles={styles.container}>
      <Avatar name={contact.info.name} size={size} image={photo} />
      {showPresence && (
        <Tooltip
          content={<ContactStatus contact={contact} />}
          trigger={<Image src={`/img/presence_${availability}.svg`} fluid styles={styles.presence} />}
        />
      )}
    </Flex>
  );
};

// default props
ContactAvatar.defaultProps = {
  showPresence: false,
};

export default ContactAvatar;
