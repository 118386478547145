import axios, { AxiosResponse } from "axios";
import IContact from "../../models/IContact";

export class People {
  public static get(): Promise<AxiosResponse<IContact[]>> {
    return axios.get<IContact[]>(`${window.location.origin}/api/people`);
  }

  public static getFromGroup(groupId: string): Promise<AxiosResponse<IContact[]>> {
    return axios.get<IContact[]>(`${window.location.origin}/api/people/groups/${groupId}`, {});
  }

  public static getFromTeamChannel(teamId: string, channelId: string): Promise<AxiosResponse<IContact[]>> {
    return axios.get<IContact[]>(`${window.location.origin}/api/people/channels/${teamId}/${channelId}`, {});
  }
}
