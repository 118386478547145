import React from "react";
import { Flex, ICSSInJSStyle } from "@fluentui/react-northstar";
import { ContactCardMemo } from "./ContactCard";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: { overflow: "auto", marginLeft: "8px" },
};

// props
interface ContactsCardsViewProps {
  contacts: IContactPresence[];
  selectedContact: IContactPresence | undefined;
  thresholdLow: number;
  thresholdMedium: number;
}

// render
const ContactsCardsView = (props: ContactsCardsViewProps) => {
  const { contacts, selectedContact, thresholdLow, thresholdMedium } = props;

  return (
    <Flex gap="gap.smaller" wrap styles={styles.container}>
      {contacts.map((contact) => (
        <ContactCardMemo
          key={contact.info.id}
          contact={contact}
          isSelected={(selectedContact && selectedContact.info.id === contact.info.id) || false}
          thresholdLow={thresholdLow}
          thresholdMedium={thresholdMedium}
        />
      ))}
    </Flex>
  );
};

export default ContactsCardsView;
