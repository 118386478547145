import React, { useContext, useEffect, useMemo, useState } from "react";
import { Flex, ICSSInJSStyle, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import ContactsListView from "../components/ContactsListView";
import ContactsCardsView from "../components/ContactsCardsView";
import IContact from "../models/IContact";
import IPresence from "../models/IPresence";
import { MyContactsContext } from "../contexts/MyContactsContext";
import MyContactsToolbar from "../components/MyContactsToolbar";
import { ViewMode } from "../types/ViewMode";
import * as gdhApi from "../api/gdh-api";
import { AppContext } from "../contexts/AppContext";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: { height: "calc(100vh - 16px)", marginTop: "16px", marginLeft: "5px", marginRight: "5px" },
};

const currentUrlParams = new URLSearchParams(document.location.search);

let supportLocalStorage = true;
try {
  localStorage.setItem("support", "OK");
  localStorage.removeItem("support");
} catch {
  supportLocalStorage = false;
}

// render
const MyContacts = () => {
  const { t } = useTranslation();
  // get personal settings from local storage
  const viewModeSaved = localStorage.getItem("ViewMode") === "cards" ? "cards" : "list";
  const showOnlyInternalUsersSaved = supportLocalStorage && localStorage.getItem("ShowOnlyInternalUsers") === "1";
  const showOnlyAvailableUsersSaved = supportLocalStorage && localStorage.getItem("ShowOnlyAvailableUsers") !== "0"; // show only available users by default

  const [viewMode, setViewMode] = useState<ViewMode>(viewModeSaved);
  const [showOnlyInternalUsers, setShowOnlyInternalUsers] = useState<boolean>(showOnlyInternalUsersSaved);
  const [showOnlyAvailableUsers, setShowOnlyAvailableUsers] = useState<boolean>(showOnlyAvailableUsersSaved);
  const showTeamContacts = currentUrlParams.get("tabType") === "team";

  const [contacts, setContacts] = useState<IContact[]>();
  const [presences, setPresences] = useState<IPresence[]>();
  const [selectedContact, setSelectedContact] = useState<IContactPresence | undefined>(undefined);

  const [thresholdLow, setThresholdLow] = useState<number>(0);
  const [thresholdMedium, setThresholdMedium] = useState<number>(0);

  const { userInfo, teamsContext } = useContext(AppContext);
  const currentUserId = userInfo?.userId;

  // get contacts
  useEffect(() => {
    if (teamsContext && contacts === undefined) {
      let peoplePromise: Promise<AxiosResponse<IContact[]>> | undefined = gdhApi.People.get();

      if (showTeamContacts) {
        if (teamsContext.groupId) {
          peoplePromise = gdhApi.People.getFromGroup(teamsContext.groupId);
        } else {
          peoplePromise = undefined;
        }
      }

      if (peoplePromise) {
        peoplePromise.then((response) => {
          const contactsWithoutCurrentUser = response.data.filter(
            (user) => user.id !== currentUserId && user.personType.class !== "Group",
          );
          setContacts(contactsWithoutCurrentUser);
        });
      } else {
        setContacts([]);
      }
    }
  }, [contacts, showTeamContacts, teamsContext, currentUserId]);

  // get presences
  useEffect(() => {
    if (!contacts || contacts.length === 0) {
      setPresences(undefined);
      return () => {};
    }

    const getPresences = () => {
      const contactIds = contacts.map((contact) => contact.id);
      gdhApi.Presence.getAll(contactIds).then((response) => setPresences(response.data));
    };

    getPresences();
    // update presences every 2 minutes
    const interval = setInterval(() => getPresences(), 1000 * 60 * 2);

    return () => clearInterval(interval);
  }, [contacts]);

  const contactsWithPresence = useMemo(() => {
    if (contacts && presences) {
      const isContactShown = (contact: IContactPresence) => {
        const internalFilter = !showOnlyInternalUsers || contact.info.personType.subClass === "OrganizationUser";
        const availableFilter = !showOnlyAvailableUsers || contact.presence?.availability === "Available";

        return internalFilter && availableFilter;
      };

      const cWithPresence = contacts.map((c) => {
        return {
          info: c,
          presence: presences.find((p) => p.id === c.id),
          isSelected: false,
        } as IContactPresence;
      });
      return cWithPresence.filter(isContactShown);
    }

    return undefined;
  }, [contacts, presences, showOnlyAvailableUsers, showOnlyInternalUsers]);

  const handleChangeShowOnlyInternalUsers = (newValue: boolean) => {
    setShowOnlyInternalUsers(newValue);
    if (supportLocalStorage) {
      localStorage.setItem("ShowOnlyInternalUsers", newValue ? "1" : "0");
    }
  };

  const handleChangeShowOnlyAvailableUsers = (newValue: boolean) => {
    setShowOnlyAvailableUsers(newValue);
    if (supportLocalStorage) {
      localStorage.setItem("ShowOnlyAvailableUsers", newValue ? "1" : "0");
    }
  };

  // define thresholds
  useEffect(() => {
    if (!showTeamContacts && contacts) {
      // flattened scores
      const scores = ([] as number[]).concat(...contacts.map((c) => c.scoredEmails.map((se) => se.score)));

      // get min, max and delta
      const min = Math.min(...scores);
      const max = Math.max(...scores);
      const delta = (max - min) / 3;

      setThresholdMedium(max - delta);
      setThresholdLow(min + delta);
    }
  }, [showTeamContacts, contacts]);

  const selectRandomContact = () => {
    if (contactsWithPresence) {
      const randomContact = contactsWithPresence[Math.floor(Math.random() * contactsWithPresence.length)];
      setSelectedContact(randomContact);
    } else {
      setSelectedContact(undefined);
    }
  };

  const contactsRenderedItems = useMemo(() => {
    if (!contactsWithPresence) return <Loader label={t("Common.LoadingContacts")} />;

    if (viewMode === "list")
      return (
        <ContactsListView
          contacts={contactsWithPresence || []}
          selectedContact={selectedContact}
          thresholdLow={thresholdLow}
          thresholdMedium={thresholdMedium}
        />
      );

    return (
      <ContactsCardsView
        contacts={contactsWithPresence || []}
        selectedContact={selectedContact}
        thresholdLow={thresholdLow}
        thresholdMedium={thresholdMedium}
      />
    );
  }, [contactsWithPresence, viewMode, thresholdLow, thresholdMedium, selectedContact, t]);

  return (
    <MyContactsContext.Provider
      value={{
        showTeamContacts,
      }}
    >
      <Flex column gap="gap.medium" styles={styles.container}>
        <MyContactsToolbar
          viewMode={viewMode}
          setViewMode={setViewMode}
          thresholdLow={thresholdLow}
          thresholdMedium={thresholdMedium}
          showOnlyInternalUsers={showOnlyInternalUsers}
          showOnlyAvailableUsers={showOnlyAvailableUsers}
          changeShowOnlyInternalUsers={handleChangeShowOnlyInternalUsers}
          changeShowOnlyAvailableUsers={handleChangeShowOnlyAvailableUsers}
          selectedContact={selectedContact}
          selectRandomContact={selectRandomContact}
        />
        {contactsRenderedItems}
      </Flex>
    </MyContactsContext.Provider>
  );
};

export default MyContacts;
