import axios, { AxiosResponse } from "axios";
import IContact from "../../models/IContact";

export class User {
  public static getMe = async (): Promise<AxiosResponse> => {
    return axios.get(`/api/user`);
  };

  // public static getPhoto = async (contact: IContact): Promise<AxiosResponse> => {
  //   return axios.get(`/api/user/${contact.id}/64X64/photo`, { responseType: "blob" });
  // };

  public static getPhoto(contact: IContact): Promise<AxiosResponse<Blob>> {
    return axios.get(`/api/user/${contact.id}/64X64/photo`, { responseType: "blob" });
  }
}
