import React, { useState } from "react";
import { Button, CloseIcon, Dialog, ICSSInJSStyle } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import DiceIcon from "../icons/DiceIcon";
import { ContactCardMemo } from "./ContactCard";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  dialog: {
    maxHeight: 320,
  },
  closeButton: ({ props, variables }: ICSSInJSStyle) => ({
    ...(props && {
      ":hover": {
        color: variables.textColorIconOnlyHover,
        background: variables.backgroundColorIconOnlyHover,
      },
    }),
  }),
};

export interface RandomSelectionProps {
  thresholdLow: number;
  thresholdMedium: number;
  selectedContact: IContactPresence | undefined;
  selectRandomContact: () => void;
}

// render
const RandomSelection = (props: RandomSelectionProps) => {
  const { thresholdLow, thresholdMedium, selectedContact, selectRandomContact } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpen={() => setOpen(true)}
      onConfirm={() => selectRandomContact()}
      onCancel={() => setOpen(false)}
      confirmButton={t("Common.TryAgain")}
      cancelButton={t("Common.Close")}
      content={
        selectedContact && (
          <ContactCardMemo
            contact={selectedContact}
            isSelected
            thresholdLow={thresholdLow}
            thresholdMedium={thresholdMedium}
          />
        )
      }
      trigger={
        <Button
          icon={<DiceIcon />}
          primary
          content={t("MyContacts.Toolbar.SelectRandom")}
          onClick={() => selectRandomContact()}
        />
      }
      header={t("MyContacts.RandomDialog.Title")}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => setOpen(false),
        styles: styles.closeButton,
      }}
      styles={styles.dialog}
    />
  );
};

export default RandomSelection;
