import React, { useEffect, useMemo, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Loader, Provider } from "@fluentui/react-northstar";
import useTheme from "./hooks/UseTheme";
import { AppContext } from "./contexts/AppContext";
import MyContacts from "./routes/MyContacts";
import Configure from "./routes/Configure";
import { Authenticate } from "./api/gdh/authenticate";
import IUserInfo from "./models/IUserInfo";

const App: React.FC = () => {
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [ssoToken, setSSOToken] = useState<string>();
  const [teamsContext, setTeamsContext] = useState<microsoftTeams.Context>();
  const { i18n } = useTranslation();
  const { theme, setThemeName } = useTheme();

  const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = useMemo(
    () => ({
      successCallback: (token) => setSSOToken(token),
      // eslint-disable-next-line no-console
      failureCallback: (error) => console.error(`SSO connection failed: ${error}`),
    }),
    [],
  );

  useEffect(() => {
    const finalizeContext = (context: microsoftTeams.Context) => {
      setTeamsContext(context);
      setThemeName(context.theme);
      microsoftTeams.appInitialization.notifyAppLoaded();
    };

    if (!teamsContext) {
      microsoftTeams.initialize();
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
      microsoftTeams.registerOnThemeChangeHandler(setThemeName);
      microsoftTeams.getContext(finalizeContext);
    }
  }, [teamsContext, i18n, setThemeName, authTokenRequest]);

  useEffect(() => {
    if (ssoToken && teamsContext) {
      Authenticate.signIn(ssoToken).then((res) => {
        setUserInfo(res.data);
        microsoftTeams.appInitialization.notifySuccess();
      });
    }
  }, [ssoToken, teamsContext]);

  // get new auth token just before expiration
  useEffect(() => {
    const expiration = userInfo ? userInfo.expiredIn - 1000 * 60 : 0;
    const timeoutAuthToken = setTimeout(() => microsoftTeams.authentication.getAuthToken(authTokenRequest), expiration);

    return () => clearTimeout(timeoutAuthToken);
  }, [authTokenRequest, userInfo]);

  return (
    <AppContext.Provider
      value={{
        userInfo,
        setUserInfo,
        ssoToken,
        setSSOToken,
        teamsContext,
        setTeamsContext,
      }}
    >
      {!!teamsContext && !!userInfo && (
        <Provider theme={theme}>
          <Router>
            <Switch>
              <Route path="/my-contacts">
                <MyContacts />
              </Route>
              <Route path="/configure">
                <Configure />
              </Route>
            </Switch>
          </Router>
        </Provider>
      )}
      {!teamsContext && <Loader />}
    </AppContext.Provider>
  );
};

export default App;
