import React from "react";
import {
  Button,
  Checkbox,
  Flex,
  ShorthandCollection,
  Toolbar,
  ToolbarItemProps,
  ToolbarItemShorthandKinds,
  ICSSInJSStyle,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { ViewMode } from "../types/ViewMode";
import RandomSelection from "./RandomSelection";
import GridIcon from "../icons/GridIcon";
import ListIcon from "../icons/ListIcon";
import IContactPresence from "../models/IContactPresence";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  toolbar: {},
};

// props
interface MyContactsToolbarProps {
  thresholdLow: number;
  thresholdMedium: number;
  showOnlyInternalUsers: boolean;
  showOnlyAvailableUsers: boolean;
  changeShowOnlyInternalUsers: (newValue: boolean) => void;
  changeShowOnlyAvailableUsers: (newValue: boolean) => void;
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  selectedContact: IContactPresence | undefined;
  selectRandomContact: () => void;
}

// render
const MyContactsToolbar = (props: MyContactsToolbarProps) => {
  const {
    thresholdLow,
    thresholdMedium,
    showOnlyInternalUsers,
    showOnlyAvailableUsers,
    changeShowOnlyInternalUsers,
    changeShowOnlyAvailableUsers,
    viewMode,
    setViewMode,
    selectedContact,
    selectRandomContact,
  } = props;
  const { t } = useTranslation();

  // Change current view mode (list or cards)
  const handleViewModeClick = (view: ViewMode) => {
    setViewMode(view);

    // Store locally
    localStorage.setItem("ViewMode", view);
  };

  const leftToolbarItems: ShorthandCollection<ToolbarItemProps, ToolbarItemShorthandKinds> = [
    {
      key: "internal-users",
      kind: "custom",
      fitted: "horizontally",
      content: (
        <Checkbox
          toggle
          label={t("MyContacts.Toolbar.ShowOnlyInternalUsers")}
          labelPosition="start"
          checked={showOnlyInternalUsers}
          onChange={(event, data) => {
            event.stopPropagation();
            changeShowOnlyInternalUsers(data?.checked || false);
          }}
        />
      ),
    },
    {
      key: "available-users",
      kind: "custom",
      content: (
        <Checkbox
          toggle
          label={t("MyContacts.Toolbar.ShowOnlyAvailableUsers")}
          labelPosition="start"
          checked={showOnlyAvailableUsers}
          onChange={(event, data) => {
            event.stopPropagation();
            changeShowOnlyAvailableUsers(data?.checked || false);
            localStorage.setItem("ShowOnlyAvailableUsers", data?.checked ? "1" : "0");
          }}
        />
      ),
    },
  ];

  const rightToolbarItems: ShorthandCollection<ToolbarItemProps, ToolbarItemShorthandKinds> = [
    {
      key: "select-random",
      kind: "custom",
      content: (
        <RandomSelection
          selectedContact={selectedContact}
          selectRandomContact={selectRandomContact}
          thresholdLow={thresholdLow}
          thresholdMedium={thresholdMedium}
        />
      ),
    },
    {
      key: "view-mode-list",
      kind: "custom",
      content: <Button primary icon={<ListIcon />} iconOnly disabled={viewMode === "list"} />,
      onClick: () => handleViewModeClick("list"),
      fitted: "horizontally",
    },
    {
      key: "view-mode-card",
      kind: "custom",
      content: <Button primary icon={<GridIcon />} iconOnly disabled={viewMode === "cards"} />,
      onClick: () => handleViewModeClick("cards"),
      fitted: "horizontally",
    },
  ];

  return (
    <Flex space="between" wrap styles={styles.toolbar}>
      <Toolbar items={leftToolbarItems} />
      <Toolbar items={rightToolbarItems} />
    </Flex>
  );
};

export default MyContactsToolbar;
