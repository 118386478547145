/* eslint-disable react/prop-types */

import * as React from "react";
import { createSvgIcon } from "@fluentui/react-icons-northstar";

const ScoreMediumIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" role="presentation" viewBox="0 0 28 25" className={classes.svg}>
      <g fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M10 10c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM16.335 7.387c-3.604-3.184-9.07-3.181-12.667 0-.203.182-.222.494-.043.697.009.01.015.019.025.025l1.075 1.063c.187.184.487.197.687.025 2.625-2.27 6.554-2.263 9.173 0 .2.172.5.162.687-.025l1.075-1.063c.194-.19.2-.503.01-.7l-.022-.022z"
                    transform="translate(-963 -631) translate(91 254) translate(0 364) translate(873 13) translate(4 6)"
                  />
                  <path
                    d="M19.841 3.84C14.303-1.282 5.693-1.279.16 3.84c-.2.188-.213.5-.029.703.007.007.01.013.016.016l1.069 1.063c.194.19.5.193.7.012 4.56-4.178 11.604-4.178 16.164 0 .2.185.506.178.7-.012l1.069-1.063c.2-.194.203-.506.009-.703-.006-.006-.013-.01-.016-.016z"
                    opacity=".3"
                    transform="translate(-963 -631) translate(91 254) translate(0 364) translate(873 13) translate(4 6)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  displayName: "ScoreMediumIcon",
});

export default ScoreMediumIcon;
