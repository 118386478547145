import React, { useContext, useState } from "react";
import { Image, Flex, ICSSInJSStyle, TableRow, Text, Tooltip } from "@fluentui/react-northstar";
import IContactPresence from "../models/IContactPresence";
import ContactAvatar from "./ContactAvatar";
import ScoreIndicator from "./ScoreIndicator";
import ContactToolbar from "./ContactToolbar";
import ContactStatus from "./ContactStatus";
import { MyContactsContext } from "../contexts/MyContactsContext";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  presenceCell: { flexGrow: 0, flexBasis: "40px", justifyContent: "center" },
  statusCell: { flexGrow: 0, flexBasis: "150px" },
  nameCell: { fontWeight: "bold" },
  relationshipCell: { flexGrow: 0, flexBasis: "80px", justifyContent: "center" },
  relationshipHiddenCell: { flexGrow: 0, flexBasis: "0px", display: "none" },
  actionsCell: { flexGrow: 0, flexBasis: "160px", justifyContent: "flex-end" },
  presenceImage: { height: "12px", width: "12px" },
};

// props
interface ContactRowProps {
  contact: IContactPresence;
  thresholdLow: number;
  thresholdMedium: number;
  isSelected: boolean;
}

// render
const ContactRow = (props: ContactRowProps) => {
  const { contact, thresholdLow, thresholdMedium, isSelected } = props;
  const { showTeamContacts } = useContext(MyContactsContext);
  const [hover, setHover] = useState<boolean>(false);
  const availability = contact.presence?.availability || "PresenceUnknown";

  return (
    <TableRow
      key={contact.info.id}
      className={`${availability === "Available" ? "available" : "unavailable"} ${
        contact.info.personType.subClass === "OrganizationUser" ? "internal" : "external"
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      selected={isSelected}
      items={[
        {
          key: `${contact.info.id}_presence`,
          content: (
            <Flex hAlign="center" vAlign="center">
              <Tooltip
                content={<ContactStatus contact={contact} />}
                trigger={<Image src={`/img/presence_${availability}.svg`} fluid styles={styles.presenceImage} />}
              />
            </Flex>
          ),
          styles: styles.presenceCell,
        },
        {
          key: `${contact.info.id}_status`,
          content: <ContactStatus contact={contact} />,
          styles: styles.statusCell,
        },
        {
          key: `${contact.info.id}_name`,
          truncateContent: true,
          content: (
            <Flex gap="gap.small" vAlign="center">
              <ContactAvatar contact={contact} size="medium" />
              <Text content={contact.info.name} weight="bold" truncated />
            </Flex>
          ),
          styles: styles.nameCell,
        },
        {
          key: `${contact.info.id}_location`,
          truncateContent: true,
          content: <Text content={contact.info.officeLocation || "-"} truncated />,
        },
        {
          key: `${contact.info.id}_score`,
          content: !showTeamContacts && (
            <ScoreIndicator
              contact={contact}
              thresholdLow={thresholdLow}
              thresholdMedium={thresholdMedium}
              size="larger"
            />
          ),
          styles: !showTeamContacts ? styles.relationshipCell : styles.relationshipHiddenCell,
        },
        {
          key: `${contact.info.id}_domain`,
          truncateContent: true,
          content: contact.info.scoredEmails.length > 0 ? contact.info.scoredEmails[0].email.split("@").pop() : "-",
        },
        {
          key: `${contact.info.id}_actions`,
          content: <ContactToolbar contact={contact} isParentHovered={hover} />,
          styles: styles.actionsCell,
        },
      ]}
    />
  );
};

export default ContactRow;
