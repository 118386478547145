import axios, { AxiosResponse } from "axios";
import IUserInfo from "../../models/IUserInfo";

export class Authenticate {
  public static signIn = async (ssoToken: string): Promise<AxiosResponse<IUserInfo>> => {
    return axios.post(
      `/api/authenticate/signin`,
      {},
      {
        headers: {
          authorization: `Bearer ${ssoToken}`,
        },
      },
    );
  };
}
