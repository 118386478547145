import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import IPresence from "../../models/IPresence";

export class Presence {
  public static getAll(contactIds: string[]): Promise<AxiosResponse<IPresence[]>> {
    const url = `${window.location.origin}/api/presence`;
    const data = contactIds;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios.post<IPresence[]>(url, data, config);
  }
}
