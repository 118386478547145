import React, { useEffect, useMemo, useState } from "react";
import { SizeValue, Tooltip } from "@fluentui/react-northstar";
import ScoreLowIcon from "../icons/ScoreLowIcon";
import ScoreMediumIcon from "../icons/ScoreMediumIcon";
import ScoreHighIcon from "../icons/ScoreHighIcon";
import IContactPresence from "../models/IContactPresence";

// props
interface ScoreIndicatorProps {
  contact: IContactPresence;
  thresholdLow: number;
  thresholdMedium: number;
  size: SizeValue;
}

// render
const ScoreIndicator = (props: ScoreIndicatorProps) => {
  const { contact, thresholdLow, thresholdMedium, size } = props;
  const [score, setScore] = useState(0);

  useEffect(() => {
    const scores = contact.info.scoredEmails.map((email) => email.score);
    setScore(Math.max(...scores));
  }, [contact]);

  const ScoreIcon = useMemo(() => {
    if (score < thresholdLow) {
      return ScoreLowIcon;
    }

    if (score < thresholdMedium) {
      return ScoreMediumIcon;
    }

    return ScoreHighIcon;
  }, [score, thresholdLow, thresholdMedium]);

  return <Tooltip content={`Score: ${score}`} trigger={<ScoreIcon size={size} />} />;
};

export default ScoreIndicator;
