/* eslint-disable react/prop-types */

import * as React from "react";
import { createSvgIcon } from "@fluentui/react-icons-northstar";

const DiceIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg focusable="false" role="presentation" viewBox="0 0 640 512" className={classes.svg}>
      <path d="M480 328c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm96-136H439.38v.01c-2.89-5.17-6.26-10.15-10.66-14.55L270.54 19.28C257.69 6.42 240.84 0 224 0s-33.69 6.42-46.54 19.28L19.28 177.46c-25.7 25.7-25.7 67.38 0 93.08l158.18 158.18C190.31 441.57 207.16 448 224 448s33.69-6.43 46.54-19.28L320 379.26V448c0 35.35 28.65 64 64 64h192c35.35 0 64-28.65 64-64V256c0-35.35-28.65-64-64-64zM235.63 393.82c-4.19 4.19-9.09 4.82-11.63 4.82s-7.44-.63-11.63-4.82L54.18 235.63c-6.42-6.42-6.42-16.86 0-23.27L212.37 54.18c4.19-4.19 9.09-4.82 11.63-4.82s7.44.63 11.63 4.82l158.19 158.18c6.42 6.41 6.42 16.85 0 23.27L235.63 393.82zM592 448c0 8.82-7.18 16-16 16H384c-8.82 0-16-7.18-16-16V331.26l60.72-60.72c8.73-8.73 14.26-19.37 17.05-30.54H576c8.82 0 16 7.18 16 16v192zM224 200c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm96 0c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm-192 0c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm96 96c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm0-192c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24z" />
    </svg>
  ),
  displayName: "DiceIcon",
});

export default DiceIcon;
