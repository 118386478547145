import { ICSSInJSStyle, pxToRem, SiteVariablesInput, ThemeInput } from "@fluentui/react-northstar";

const gdhCommonTheme: ThemeInput = {
  componentStyles: {
    Button: {
      root: ({ props, variables }: ICSSInJSStyle) => ({
        ...(props.iconOnly && {
          ...(!props.inverted && {
            ":hover": {
              color: variables.primaryColorHover,
              background: variables.primaryBackgroundColorHover,
            },
          }),
        }),
      }),
    },
    Card: {
      root: ({ props, theme }: ICSSInJSStyle) => ({
        ...(props && {
          minHeight: pxToRem(150),
          ":hover": {
            color: theme.siteVariables.colorScheme.brand.foreground4,
          },
          ...(props.selected && {
            color: theme.siteVariables.colorScheme.brand.foreground4,
          }),
        }),
      }),
    },
    Checkbox: {
      label: ({ props }: ICSSInJSStyle) => ({
        ...(props && {
          fontWeight: "bold",
        }),
      }),
    },
    TableRow: {
      root: ({ props }: ICSSInJSStyle) => ({
        ...(props.header && {
          marginBottom: pxToRem(8),
        }),
      }),
    },
  },
  componentVariables: {
    Card: (siteVariables: SiteVariablesInput) => ({
      backgroundColor: siteVariables.colorScheme.default.background,
      backgroundColorHover: siteVariables.colorScheme.brand.backgroundHover,
      selectedBackgroundColor: siteVariables.colorScheme.brand.backgroundHover,
      selectedBackgroundColorHover: siteVariables.colorScheme.brand.backgroundHover,
      width: pxToRem(325),
    }),
    Dialog: {
      rootWidth: "390px",
    },
    Provider: {
      background: "transparent",
    },
    Table: {
      backgroundColor: "transparent",
    },
    TableRow: (siteVariables: SiteVariablesInput) => ({
      backgroundHoverColor: siteVariables.colorScheme.brand.backgroundHover,
      hoverColor: siteVariables.colorScheme.brand.foreground4,
    }),
    ToolbarCustomItem: {
      customItemHorizontalPadding: pxToRem(10),
    },
    ToolbarItem: {
      itemHeight: pxToRem(20),
      itemPadding: `${pxToRem(0)} ${pxToRem(5)}`,
    },
  },
  siteVariables: {
    colorScheme: {
      greenDotHopper: {
        backgroundInactive: "#ABACCE",
        backgroundDisabled: "#ABACCE",
      },
    },
    fontSizes: {
      large: pxToRem(15),
    },
  },
};

export default gdhCommonTheme;
