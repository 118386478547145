import React, { useContext, useEffect } from "react";
import { Flex, ICSSInJSStyle, Loader } from "@fluentui/react-northstar";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import * as microsoftTeams from "@microsoft/teams-js";
import { AppContext } from "../contexts/AppContext";

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: { height: "100vh" },
};

// render
const Configure: React.FC = () => {
  const { teamsContext } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (teamsContext) {
      if (teamsContext.groupId) {
        const contentUrl = `${window.location.origin}/my-contacts/?tabType=team&groupId={groupId}`;
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          microsoftTeams.settings.setSettings({
            contentUrl,
            entityId: `${teamsContext.teamId}-${uuidv4()}`,
            suggestedDisplayName: t("Configure.TabSuggestedDisplayName"),
          });
          saveEvent.notifySuccess();
        });

        microsoftTeams.settings.setValidityState(true);
      }
    }
  });

  if (!teamsContext) {
    return <Loader />;
  }

  return (
    <Flex column gap="gap.medium" styles={styles.container} fill>
      {teamsContext.groupId ? (
        <div>{t("Configure.Description", { teamName: teamsContext.teamName })}</div>
      ) : (
        <div>{t("Configure.NotAvailableForPrivateChannel")}</div>
      )}
    </Flex>
  );
};

export default Configure;
