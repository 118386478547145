import { checkboxSlotClassNames, ICSSInJSStyle, SiteVariablesInput, ThemeInput } from "@fluentui/react-northstar";

const gdhDarkTheme: ThemeInput = {
  componentStyles: {
    Checkbox: {
      root: ({ props }: ICSSInJSStyle) => ({
        ...(props && {
          ":hover": {
            [`& .${checkboxSlotClassNames.indicator}`]: {
              ...(props.toggle &&
                !props.disabled && {
                  ":before": {
                    background: "white",
                    borderColor: "white",
                  },
                }),
            },
          },
        }),
      }),
      toggle: ({ props, theme }: ICSSInJSStyle) => ({
        ...(props && {
          ":before": {
            background: "white",
            borderColor: "white",
          },
          ...(!props.checked && {
            background: theme.siteVariables.colorScheme.greenDotHopper.backgroundDisabled,
          }),
        }),
      }),
    },
  },
  componentVariables: {
    Button: (siteVariables: SiteVariablesInput) => ({
      backgroundColorDisabled: siteVariables.colorScheme.greenDotHopper.backgroundDisabled,
      colorDisabled: "white",
    }),
    Checkbox: (siteVariables: SiteVariablesInput) => ({
      borderColor: siteVariables.colorScheme.greenDotHopper.backgroundDisabled,
      borderColorHover: siteVariables.colorScheme.greenDotHopper.backgroundDisabled,
    }),
  },
};

export default gdhDarkTheme;
